<template>
	<NuxtLink
		ref="target"
		:to="
			notification.entityId && notification.instanceId
				? getEntityUrl(notification.entityId, notification.instanceId)
				: undefined
		"
		class="relative flex items-center gap-x-4 rounded border px-2 py-1 transition-all"
		:class="{
			'border-transparent hover:border-primary-200 hover:bg-primary-100':
				notification.entityId && notification.instanceId,
			'border-transparent bg-primary-50': !isReadOriginalValue,
		}"
	>
		<div class="relative flex-none">
			<img
				:src="notification.responsible.profilePicture"
				:alt="notification.responsible.fullName"
				class="h-10 w-10 rounded-full bg-slate-50"
			/>
			<!-- Entity image instead of icon -->
			<div
				v-if="
					notification.entity &&
					['Employee', 'Account'].includes(notification.entity) &&
					notification.instanceImage
				"
				class="absolute -right-2 top-6 size-6 rounded-full border border-white bg-white shadow-sm"
			>
				<img
					:src="notification.instanceImage"
					:alt="notification.instanceName"
					class="h-full w-full rounded-full object-cover"
				/>
			</div>
		</div>

		<div class="flex-auto">
			<!-- Entityevent -->
			<div
				v-if="notification.entityEventId"
				class="mt-1 text-sm leading-[1.25] text-slate-700"
			>
				<span class="font-semibold">{{
					notification.responsible.fullName
				}}</span>
				<span
					v-dompurify-html="
						' ' +
						notification.message.charAt(0).toLowerCase() +
						notification.message.slice(1)
					"
				>
				</span>

				<span v-if="notification.groupedNotificationIds.length > 0">
					og gjorde
					{{ notification.groupedNotificationIds.length }} andre
					endringer
				</span>
				<span> på </span>
				<span class="font-semibold">{{
					notification.instanceName
				}}</span>
			</div>
			<div v-else class="mt-1 text-sm leading-5 text-slate-700">
				<span v-dompurify-html="notification.message"> </span>
			</div>

			<time
				:datetime="formatDateTime(notification.createdOn)"
				:title="formatDateTime(notification.createdOn)"
				class="-mt-px block flex-none text-xs text-slate-500"
			>
				{{ localizedTimeAgo }}
			</time>
		</div>
		<div
			v-if="!isReadOriginalValue"
			class="h-2 w-2 shrink-0 rounded-full bg-primary"
		></div>
	</NuxtLink>
</template>

<script setup lang="ts">
import { fromZonedTime } from 'date-fns-tz';
import { NotificationItem } from '~/codegen/axos-client';

const props = defineProps<{
	notification: NotificationItem;
	disableRead?: boolean;
}>();

const target = ref<HTMLElement | null>(null);
const targetIsVisible = useElementVisibility(target);

const isReadOriginalValue = props.notification.isRead;
const markedAsRead = ref(false);

const { api } = useApiClient();
const { invalidate } = useInvalidateQuery();

if (!props.disableRead) {
	watch(targetIsVisible, (isVisible) => {
		if (isVisible && !isReadOriginalValue && !markedAsRead.value) {
			markedAsRead.value = true;
			api.markNotificationAsRead(props.notification.id);
			invalidate(['notifications']);
		}
	});
}

const { getEntityUrl } = useEntityUrl();

const { localizedTimeAgo } = useLocalizedTimeAgo(
	new Date(props.notification.createdOn)
);

function formatDateTime(timestamp: string): string {
	return new Date(fromZonedTime(timestamp, 'UTC')).toLocaleString('nb-NO', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
	});
}
</script>
