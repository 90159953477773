<template>
	<section
		class="fade-in-top col-span-12 flex"
		:class="{
			'mx-auto w-full max-w-6xl': width === 'narrow',
			'flex-col': !image,
			'flex-row-reverse ': image,
			'border border-slate-200': !noShadow,
			'rounded-xl bg-white': !noBg,
			[props.class]: !!$props.class,
		}"
	>
		<div
			v-if="title"
			class="flex min-h-9 items-center justify-between rounded-t-xl py-2"
			:class="{
				'rounded-b-xl': !isExpanded,
				'px-6 2xl:px-7 ': !noPadding,
				'bg-white': !noBg,
			}"
		>
			<div
				class="flex items-center justify-between"
				@click="togglable ? (isExpanded = !isExpanded) : null"
				:class="{
					'cursor-pointer pr-1': togglable,
				}"
			>
				<span
					v-if="titlePrefix"
					class="text-xs font-medium text-slate-500"
				>
					{{ titlePrefix }}
				</span>
				<h2
					v-else-if="title"
					class="min-h-9 text-base font-semibold leading-9 text-slate-600"
				>
					<template v-if="$slots.title">
						<slot name="title"></slot>
					</template>
					<template v-else>
						{{ title }}
					</template>
				</h2>
				<p v-if="description" class="mt-1 text-sm text-slate-500">
					{{ description }}
				</p>
			</div>
			<Icon
				v-if="togglable"
				:name="ICON_CHEVRON_RIGHT"
				class="mr-auto size-5 text-slate-400 transition-transform"
				:class="{
					'rotate-90': isExpanded,
					'cursor-pointer': togglable,
				}"
				aria-hidden="true"
				@click="togglable ? (isExpanded = !isExpanded) : null"
			/>

			<template v-if="isExpanded">
				<slot name="action"></slot>
			</template>
		</div>
		<div
			v-if="isExpanded"
			class="flex flex-col"
			:class="{
				'col-span-12 grid grid-cols-12': hasCols,

				'px-6 pb-4 pt-2 2xl:px-7 2xl:pb-6': !noPadding,
				'w-2/3 rounded-l-none border-l-0': image,
			}"
		>
			<slot></slot>
		</div>
		<div
			v-if="image"
			class="w-1/3 self-stretch rounded-l-md bg-cover bg-center"
			:style="`background-image: url(${image})`"
		></div>
	</section>
</template>
<script setup lang="ts">
interface Props {
	titlePrefix?: string;
	title?: string;
	description?: string;

	hasCols?: boolean;
	image?: string;

	class?: any;
	noPadding?: boolean;
	noShadow?: boolean;
	noBg?: boolean;

	/**
	 * narrow, default, full
	 */
	width?: string;
	togglable?: boolean;
	isExpanded?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	togglable: true,
	isExpanded: true,
});
const isExpanded = ref(props.isExpanded);
</script>
