import { NuxtError } from 'nuxt/app';

export default function () {
	const { hasModule } = useModules();
	const { hasCapability } = useCapabilities();

	function noAccessError() {
		const noAccessError: Partial<NuxtError> = {
			statusCode: 403,
			message: 'You do not have access to this resource',
			fatal: true,
		};

		return createError(noAccessError);
	}

	const { profile } = useProfile();
	const isAdmin = computed(() => profile.value?.isAdmin ?? false);
	const isEmployeeManager = computed(
		() => profile.value?.isEmployeeManager ?? false
	);
	const isDeveloper = computed(() => profile.value?.isDeveloper ?? false);

	return {
		hasModule,
		hasRowAccess: useRowAccessAsync,
		hasCapability,
		hasCapabilityAsync: useCapabilityAccessAsync,
		noAccessError,
		isAdmin,
		isEmployeeManager,
		isDeveloper,
	};
}
