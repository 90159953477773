<template>
	<div ref="containerRef" class="relative">
		<div
			ref="triggerRef"
			@click="togglePopover"
			aria-haspopup="dialog"
			:aria-expanded="model"
			:aria-controls="popoverId"
			role="button"
			tabindex="0"
			@keydown.enter="togglePopover"
			@keydown.space.prevent="togglePopover"
		>
			<slot name="button"></slot>
		</div>

		<Transition
			enter-active-class="transition ease-out duration-200"
			enter-from-class="opacity-0 translate-y-1"
			enter-to-class="opacity-100 translate-y-0"
			leave-active-class="transition ease-in duration-150"
			leave-from-class="opacity-100 translate-y-0"
			leave-to-class="opacity-0 translate-y-1"
			@after-enter="handleAfterEnter"
			@after-leave="handleAfterLeave"
		>
			<div
				v-if="model"
				ref="popoverContent"
				:id="popoverId"
				:class="`ax-popover z-50 mt-1 min-w-[14rem] focus:outline-none lg:w-max ${getWidthClass()} ${popoverClass}`"
				popover="manual"
				role="dialog"
				aria-modal="true"
				:aria-labelledby="`${popoverId}-title`"
				:aria-describedby="`${popoverId}-description`"
				tabindex="-1"
				@keydown.tab="handleTabKey"
				@keydown.shift.tab="handleShiftTabKey"
			>
				<div :id="`${popoverId}-title`" class="sr-only">
					<slot name="title">Popover content</slot>
				</div>
				<div :id="`${popoverId}-description`">
					<slot></slot>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script setup lang="ts">
import {
	onClickOutside,
	useResizeObserver,
	useMutationObserver,
	tryOnBeforeUnmount,
} from '@vueuse/core';

interface Props {
	origin?: 'left' | 'right';
	popoverClass?: string;
	width?: 'narrow' | 'default' | 'wide';
	returnFocus?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	origin: 'left',
	popoverClass: '',
	width: 'default',
	returnFocus: true,
});

const model = defineModel<boolean>();
const emit = defineEmits<{
	show: [];
	hide: [];
	'update:modelValue': [value: boolean];
}>();

const containerRef = ref<HTMLElement | null>(null);
const triggerRef = ref<HTMLElement | null>(null);
const popoverContent = ref<HTMLElement | null>(null);
const popoverId = ref(`popover-${Math.random().toString(36).substr(2, 9)}`);
const previousActiveElement = ref<HTMLElement | null>(null);

// Focus management
function handleAfterEnter() {
	previousActiveElement.value = document.activeElement as HTMLElement;
	nextTick(() => {
		const firstFocusable = popoverContent.value?.querySelector<HTMLElement>(
			'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
		);
		firstFocusable?.focus();
	});
}

function handleAfterLeave() {
	if (props.returnFocus && previousActiveElement.value) {
		previousActiveElement.value.focus();
	}
}

function handleTabKey(event: KeyboardEvent) {
	const focusableElements =
		popoverContent.value?.querySelectorAll<HTMLElement>(
			'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
		);
	if (!focusableElements?.length) return;

	const firstElement = focusableElements[0];
	const lastElement = focusableElements[focusableElements.length - 1];

	if (event.target === lastElement) {
		event.preventDefault();
		firstElement.focus();
	}
}

function handleShiftTabKey(event: KeyboardEvent) {
	const focusableElements =
		popoverContent.value?.querySelectorAll<HTMLElement>(
			'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
		);
	if (!focusableElements?.length) return;

	const firstElement = focusableElements[0];
	const lastElement = focusableElements[focusableElements.length - 1];

	if (event.target === firstElement) {
		event.preventDefault();
		lastElement.focus();
	}
}

// Event handlers
function handleEscapeKey(event: KeyboardEvent) {
	if (event.key === 'Escape' && model.value) {
		closePopover();
	}
}

function handleScroll() {
	if (model.value) {
		updatePosition();
	}
}

// Core functionality
function togglePopover() {
	try {
		const willShow = !model.value;
		model.value = willShow;

		if (willShow) {
			nextTick(() => {
				updatePosition();
				popoverContent.value?.showPopover();
				emit('show');
			});
		} else {
			closePopover();
		}
	} catch (error) {
		console.error('Error toggling popover:', error);
		closePopover();
	}
}

function closePopover() {
	model.value = false;
	popoverContent.value?.hidePopover();
	emit('hide');
}

function updatePosition() {
	if (!containerRef.value || !popoverContent.value) return;

	try {
		requestAnimationFrame(() => {
			const rect = containerRef.value!.getBoundingClientRect();
			const scrollY = window.scrollY;
			const scrollbarWidth =
				window.innerWidth - document.documentElement.clientWidth;
			const OFFSET = 16;

			Object.assign(popoverContent.value!.style, {
				position: 'absolute',
				top: `${rect.bottom + scrollY}px`,
				[props.origin]: `${
					props.origin === 'left'
						? rect.left - OFFSET
						: window.innerWidth -
						  rect.right -
						  scrollbarWidth -
						  OFFSET
				}px`,
				transform: 'translateZ(0)',
			});
		});
	} catch (error) {
		console.error('Error updating popover position:', error);
	}
}

// Lifecycle hooks and observers
onMounted(() => {
	window.addEventListener('scroll', handleScroll, { passive: true });
	window.addEventListener('keydown', handleEscapeKey);

	if (containerRef.value) {
		let parent = containerRef.value.parentElement;
		while (parent) {
			if (isScrollable(parent)) {
				parent.addEventListener('scroll', handleScroll, {
					passive: true,
				});
			}
			parent = parent.parentElement;
		}
	}
});

tryOnBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
	window.removeEventListener('keydown', handleEscapeKey);

	if (containerRef.value) {
		let parent = containerRef.value.parentElement;
		while (parent) {
			if (isScrollable(parent)) {
				parent.removeEventListener('scroll', handleScroll);
			}
			parent = parent.parentElement;
		}
	}
});

// Observers
useResizeObserver(containerRef, () => {
	if (model.value) updatePosition();
});

useMutationObserver(
	containerRef,
	() => {
		if (model.value) updatePosition();
	},
	{
		attributes: true,
		childList: true,
		subtree: true,
		characterData: true,
	}
);

// Click outside handler
onClickOutside(popoverContent, (event) => {
	if (model.value && !triggerRef.value?.contains(event.target as Node)) {
		closePopover();
	}
});

// Public API
defineExpose({
	openPopover: () => {
		model.value = true;
		emit('show');
	},
	closePopover,
	updatePosition,
});

// Utility functions
function getWidthClass(): string {
	switch (props.width) {
		case 'narrow':
			return 'lg:max-w-xs';
		case 'wide':
			return 'lg:w-96 lg:max-w-96';
		default:
			return 'lg:w-72 lg:max-w-sm';
	}
}

function isScrollable(element: HTMLElement): boolean {
	const hasScrollableContent = element.scrollHeight > element.clientHeight;
	const overflowYStyle = window.getComputedStyle(element).overflowY;
	return (
		hasScrollableContent &&
		(overflowYStyle === 'auto' || overflowYStyle === 'scroll')
	);
}

// Remove the triggerObserver code and add this instead:
function watchTriggerPosition() {
	if (!triggerRef.value || !model.value) return;

	let previousRect = triggerRef.value.getBoundingClientRect();

	function checkPosition() {
		if (!triggerRef.value || !model.value) return;

		const currentRect = triggerRef.value.getBoundingClientRect();

		if (
			previousRect.top !== currentRect.top ||
			previousRect.left !== currentRect.left ||
			previousRect.bottom !== currentRect.bottom ||
			previousRect.right !== currentRect.right
		) {
			updatePosition();
			previousRect = currentRect;
		}

		requestAnimationFrame(checkPosition);
	}

	const animationFrame = requestAnimationFrame(checkPosition);

	return () => cancelAnimationFrame(animationFrame);
}

// Update the watch section to start/stop position watching
watch(model, (newValue) => {
	if (newValue) {
		watchTriggerPosition();
	}
});
</script>
