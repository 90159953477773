<template>
	<div class="ax-button-wrapper">
		<component
			:is="link ? NuxtLink : 'button'"
			type="button"
			class="ax-button"
			:class="{
				/* THEMES */

				// Primary
				' border-primary-600/50 bg-primary-500 text-white hover:bg-primary-600 focus-visible:ring-primary-600 ':
					theme === 'primary',

				// Secondary
				' border-primary-100/75 bg-primary-50 text-primary-900 hover:bg-primary-100  hover:text-primary-950 focus-visible:ring-secondary-300':
					theme === 'secondary',

				// Success
				' border-green-600/50 bg-green-500 text-white hover:bg-green-600 focus-visible:ring-green-600 ':
					theme === 'success',

				// Danger
				'border-red-200/50 bg-red-100 text-red-800 hover:bg-red-200 hover:text-red-900 focus-visible:ring-red-400 ':
					theme === 'danger',

				// Neutral
				' border-slate-300/50 bg-slate-200 text-slate-800 hover:bg-slate-300 focus-visible:ring-slate-400':
					theme === 'neutral',

				// Warning
				'border-yellow-200/50 bg-yellow-100 text-yellow-800 hover:bg-yellow-200  focus-visible:ring-yellow-400 ':
					theme === 'warning',

				// Dark
				'border-slate-800/50 bg-slate-700 text-slate-50 hover:bg-slate-800 focus-visible:ring-slate-700':
					theme === 'dark',

				// Light
				'border-solid border-slate-200 bg-white text-slate-700 hover:bg-slate-100 hover:text-slate-800 focus-visible:ring-slate-400':
					theme === 'light',

				// Border?
				'!border-none !p-0': appearance === 'link',
				border: appearance !== 'link',

				// Type: Link
				'!text-primary-800 hover:!text-primary-900':
					(appearance === 'link' || appearance === 'ghost') &&
					theme === 'primary',

				'!text-primary-500 hover:!text-primary-600':
					(appearance === 'link' || appearance === 'ghost') &&
					theme === 'secondary',

				'!text-green-500 hover:!text-green-600':
					(appearance === 'link' || appearance === 'ghost') &&
					theme === 'success',

				'!text-red-600 hover:!text-red-700':
					(appearance === 'link' || appearance === 'ghost') &&
					theme === 'danger',

				' !text-slate-400 hover:!text-slate-600':
					(appearance === 'link' || appearance === 'ghost') &&
					theme === 'light',

				// Types: Link, ghost
				'flex items-center !bg-transparent !shadow-none hover:underline hover:shadow-none':
					appearance === 'link' || appearance === 'ghost',

				// Type: Default
				'rounded-md': appearance === 'default' || !appearance,

				// Type: Outline
				'border border-solid border-slate-200': appearance === 'ghost',

				// Type: Rounded
				'rounded-full': appearance === 'rounded',

				// Size: Default
				'h-8 px-2 py-1 text-xs sm:h-[2.2rem] sm:px-3 sm:py-1.5 sm:text-sm ':
					!size || size === 'default' || size === 'small',

				// Size: Xtra small
				'h-7 rounded px-2 py-1 text-xs': size === 'xsmall',

				// Size: Xtra small with no text
				'!h-6': size === 'xsmall' && appearance === 'link',

				// Size: Large
				'text-md px-4 py-2': size === 'large',

				// Size: X-Large
				'px-5 py-2.5 text-xl': size === 'xlarge',

				// Size: X-Large
				'px-4 py-2 text-xl tracking-tight sm:px-6 sm:py-3 sm:text-3xl':
					size === 'xxlarge',

				// No padding
				'!px-0 !py-0': props?.noPadding,

				// Loading
				'pointer-events-none opacity-75': loading,

				// Full width
				'w-full justify-center': props?.fullWidth,

				// Disabled
				'cursor-not-allowed opacity-40': disabled,
			}"
			:aria-label="ariaLabel"
			:title="ariaLabel"
			:disabled="loading || disabled"
			v-bind="$attrs"
		>
			<span v-if="icon && !loading" :class="iconClasses">
				<Icon :name="icon" class="color-current h-full w-full" />
			</span>

			<span v-if="loading" data-test="loading-icon">
				<Icon
					name="svg-spinners:ring-resize"
					class="color-current"
					:class="iconClasses"
				/>
			</span>

			<span
				v-if="$slots.default"
				:class="{ 'hidden lg:inline-block': hideTextOnSmallScreen }"
			>
				<slot> </slot>
			</span>
		</component>
	</div>
</template>

<script lang="ts">
export default {
	inheritAttrs: false,
};
</script>

<script setup lang="ts">
import type { ButtonProps } from '~/types/button';

const NuxtLink = resolveComponent('NuxtLink');

const props = withDefaults(defineProps<ButtonProps>(), {
	theme: 'primary',
});

const iconClasses = computed(() => ({
	'size-5':
		!props.size || props.iconSize === 'default' || props.size === 'small',
	'size-4': props.size === 'xsmall',
	'size-6': props.size === 'large' || props.iconSize === 'large',
	'order-2': props.iconPlacement === 'right',
}));
</script>
