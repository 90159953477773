import { useTimeAgo } from '@vueuse/core';
import { fromZonedTime } from 'date-fns-tz';

interface Options {
	_disableUtcForGraphql?: boolean;
}

export function useLocalizedTimeAgo(
	date: Date | number | string | MaybeRef<string>,
	options?: Options
) {
	const timeAgo = useTimeAgo(
		options?._disableUtcForGraphql
			? date
			: computed(() => fromZonedTime(unref(date), 'UTC'))
	);
	const { currentLanguageId } = useAppLanguage();

	// return english timeAgo if current language is not norwegian
	if (currentLanguageId.value != 1) {
		return { localizedTimeAgo: timeAgo };
	}

	const localizedTimeAgo = computed(() => {
		const value = timeAgo.value;

		return value
			.replace('just now', 'akkurat nå')
			.replace('in a few seconds', 'om noen sekunder')
			.replace('second ago', 'sekund siden')
			.replace('seconds ago', 'sekunder siden')
			.replace('in a minute', 'om et minutt')
			.replace('minute ago', 'minutt siden')
			.replace('minutes ago', 'minutter siden')
			.replace('in an hour', 'om en time')
			.replace('hour ago', 'time siden')
			.replace('hours ago', 'timer siden')
			.replace('a day ago', 'en dag siden')
			.replace('yesterday', 'i går')
			.replace('tomorrow', 'i morgen')
			.replace('days ago', 'dager siden')
			.replace('a week ago', 'en uke siden')
			.replace('last week', 'forrige uke')
			.replace('next week', 'neste uke')
			.replace('weeks ago', 'uker siden')
			.replace('a month ago', 'en måned siden')
			.replace('last month', 'forrige måned')
			.replace('next month', 'neste måned')
			.replace('months ago', 'måneder siden')
			.replace('a year ago', 'ett år siden')
			.replace('last year', 'i fjor')
			.replace('next year', 'neste år')
			.replace('years ago', 'år siden');
	});

	return {
		localizedTimeAgo,
	};
}
