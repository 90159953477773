import { AxosEntities, NumberingListItem } from '~/codegen/axos-client';
import { pascalCase } from 'change-case-all';

const numberings = ref<NumberingListItem[]>([]);

export default function () {
	const { data, ...args } = useQuery({
		queryKey: ['numberings'],
		queryFn: () => $api.getNumberingList(),
		staleTime: Number.POSITIVE_INFINITY,
	});

	watch(data, (newData) => {
		if (newData) {
			numberings.value = newData;
		}
	});

	return {
		numberings: data,
		...args,
	};
}

export function $formatNumbering(
	entity: AxosEntities | number | string,
	number?: string
) {
	if (!number) {
		return '';
	}

	const numbering = numberings.value.find(
		(n) =>
			n.entityId === entity ||
			n.entityName === entity ||
			n.entityName === pascalCase(entity.toString())
	);

	// Remove all non-digits from number for backward compatibility
	const digits = number.replace(/\D/g, '');

	if (!numbering) {
		return digits;
	}

	return `${numbering.prefix}${digits}`;
}
