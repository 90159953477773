<template>
	<PopoverNative origin="right" v-model="showInbox" width="wide">
		<template #button>
			<button
				class="group mr-2 cursor-pointer border-r border-white/25 p-1 pr-5"
			>
				<span class="flex items-center">
					<div
						class="relative flex size-9 items-center justify-center rounded-full border border-white/10 bg-white/20"
					>
						<Icon
							:name="
								unreadCount || showInbox
									? 'ph:bell-fill'
									: 'ph:bell-duotone'
							"
							class="size-6 text-white transition-transform group-hover:scale-110 group-hover:transform"
							aria-label="Notifications"
						/>
					</div>

					<span
						v-if="unreadCount"
						class="absolute left-6 top-0 block h-5 w-5 rounded-full bg-red-400 text-center font-semibold leading-5 text-white"
						:class="[unreadCount >= 10 ? 'text-xxs' : 'text-xs']"
					>
						{{ unreadCount }}
					</span>
				</span>
			</button>
		</template>

		<div v-if="allNotifications" class="flex flex-col">
			<template v-if="allNotifications.length === 0">
				<div class="p-4 pb-2 text-center text-sm text-slate-400">
					{{ $s('Core.Label.NoNotifications') }}
				</div>
			</template>

			<div
				class="ax-scrollbar-thin max-h-[60svh] overflow-y-auto overscroll-contain"
				@scroll="handleScroll"
			>
				<ul role="list" class="flex flex-col gap-1 p-1.5">
					<NotificationInboxItem
						v-for="notification in allNotifications"
						:notification="notification"
						:key="notification.id"
					/>
				</ul>

				<div v-if="isFetchingNextPage" class="p-4 text-center">
					<span class="text-sm text-slate-400">{{
						$s('Core.Label.LoadingNotifications')
					}}</span>
				</div>
			</div>
		</div>

		<div v-else class="p-4 text-center text-sm text-slate-400">
			{{ $s('Core.Label.NoNotifications') }}
		</div>
	</PopoverNative>
</template>

<script setup lang="ts">
import { useInfiniteQuery } from '@tanstack/vue-query';

const { api } = useApiClient();
const {
	data: notificationsData,
	fetchNextPage,
	hasNextPage,
	isFetchingNextPage,
} = useInfiniteQuery({
	queryKey: ['notifications'],
	initialPageParam: 1,
	queryFn: ({ pageParam = 1 }) =>
		api.getNotificationList(pageParam, PAGE_SIZE),
	getNextPageParam: (lastPage, allPages) => {
		return lastPage.length === PAGE_SIZE ? allPages.length + 1 : undefined;
	},
	staleTime: Number.POSITIVE_INFINITY,
});

const allNotifications = computed(
	() => notificationsData.value?.pages.flat() ?? []
);

function handleScroll(event: Event) {
	const element = event.target as HTMLElement;
	const { scrollTop, scrollHeight, clientHeight } = element;

	if (
		scrollHeight - scrollTop - clientHeight < 50 &&
		hasNextPage.value &&
		!isFetchingNextPage.value
	) {
		fetchNextPage();
	}
}

const showInbox = ref(false);

const route = useRoute();
watch(
	() => route.fullPath,
	() => {
		showInbox.value = false;
	}
);

const PAGE_SIZE = 20;

const unreadCount = computed(
	() => allNotifications.value?.filter((n) => !n.isRead).length ?? null
);
</script>
