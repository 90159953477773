import { useQuery } from '@tanstack/vue-query';
interface AuthInfo {
	email: string;
	email_verified: boolean;
	family_name: string;
	given_name: string;
	hasClient: boolean;
	currentClientId: number;
	name: string;
	preferred_username: string;
	sub: string;
}

export default function () {
	const config = useRuntimeConfig();
	const { login } = useAuthAxos();
	const queryClient = useQueryClient();

	const {
		data: authState,
		suspense,
		error,
	} = useQuery({
		queryKey: ['authStateMe'],
		queryFn: async () => {
			const { data, error } = await useFetch<AuthInfo>('/me', {
				baseURL: config.public.BFF_URL,
				credentials: 'include',
			});

			if (error.value) {
				throw error.value;
			}

			return data.value;
		},
		staleTime: Number.POSITIVE_INFINITY,
	});

	const { currentClientId } = useAuthClient();
	watch(authState, () => {
		currentClientId.value = authState.value?.currentClientId ?? null;
	});

	watch(error, (authError) => {
		if (authError?.statusCode === 401) {
			queryClient.clear();
			login();
		}
	});

	const isAuthenticated = computed(() => !!authState.value?.sub);
	const hasClient = computed(() => !!authState.value?.hasClient);

	return { isAuthenticated, suspense, hasClient, error };
}
