import { ProfileDto } from '~/types/axos-api';

export default function useProfile() {
	const query = gql`
		query getProfile {
			profile {
				email
				firstName
				id
				lastName
				initials
				profilePicture
				isAdmin
				organisationId
				organisationName
				organisationLogo
				organisationCountryId
				organisationSymbol
				languageId
				isDeveloper
				isEmployeeManager

				isImpersonated
				impersonatedBy {
					id
					firstName
					lastName
				}
			}
		}
	`;

	const { data, ...args } = useLegacyQuery(query, {
		key: ['profile'],
		queryOptions: {
			staleTime: Number.POSITIVE_INFINITY, // 24 hours
			cacheTime: 1000 * 60 * 10, // 10 minutes
		},
	});

	// Set app language based on profile
	const { setLanguageId } = useAppLanguage();
	watch(data, (value) => setLanguageId(value?.profile?.languageId ?? 1));

	return {
		profile: computed(() => data.value?.profile) as ComputedRef<ProfileDto>,
		...args,
	};
}
