<template>
	<div v-if="editor" class="editor rounded">
		<div
			class="sticky top-14 z-10 rounded-t border border-slate-200 bg-slate-50 p-1.5"
		>
			<PowerEditorToolbar
				:editor="editor"
				:ai-options="defaultAiTextOptions"
				:ai-loading="aiIsLoading"
				@upload-image="onUploadImageClick"
			/>
		</div>

		<editor-content :editor="editor" />
	</div>
</template>
<script setup lang="ts">
import Ai, { TextOptions } from '@tiptap-pro/extension-ai';
import FileHandler from '@tiptap-pro/extension-file-handler';
import Image from '@tiptap/extension-image';
import StarterKit from '@tiptap/starter-kit';
import { Editor, EditorContent, useEditor } from '@tiptap/vue-3';
import { ShallowRef } from 'vue';

const props = defineProps({
	context: Object,
	disabled: Boolean,
});

function handleInput(value: string | undefined) {
	props.context?.node.input(value);
}

const aiIsLoading = ref(false);

const { upload } = useImageUpload();

const editor = useEditor({
	content: props.context?.value ?? ('' as string),
	extensions: [
		StarterKit,
		Ai.configure({
			appId: 'e972pvk0',
			token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwiaWF0IjoxNTE2MjM5MDIyLCJleHAiOjE5MTMyMTkwNTQsImlzcyI6Imh0dHBzOi8vYXhvcy5ubyIsImF1ZCI6IjA1MTI0ODA4LWY1ZTMtNGFiNC04ZTgzLTkzNmIyMjI3NmZhYSJ9.uJig9-GC1oHqM6fl_xNh_utzwomzwBGqWKeQuYOXMCo',
			autocompletion: false,
			onLoading: () => {
				aiIsLoading.value = true;
			},
			onSuccess: () => {
				aiIsLoading.value = false;
			},
			onError: (error) => {
				aiIsLoading.value = false;
			},
		}),
		Image,
		FileHandler.configure({
			allowedMimeTypes: [
				'image/png',
				'image/jpeg',
				'image/gif',
				'image/webp',
			],
			onDrop: (currentEditor, files, pos) => {
				files.forEach(async (file) => {
					await uploadAndAddImage(file, pos);
				});
			},
			onPaste: (currentEditor, files, htmlContent) => {
				files.forEach(async (file) => {
					if (htmlContent) {
						return false;
					}

					await uploadAndAddImage(file);
				});
			},
		}),
	],
	editorProps: {
		attributes: {
			class: 'prose prose-base p-4 py-6 w-full max-w-full min-h-[18rem] h-full focus:ring-primary-500 focus:outline-none focus:ring-2 transition-colors focus:ring-inset leading-6 bg-white border border-slate-200 border-t-0 rounded-b ',
		},
	},
	onUpdate() {
		handleInput(editor.value?.getHTML());
	},
	onCreate() {
		if (props.context?.attrs.autofocus) {
			editor.value.commands.focus();
		}
	},
}) as ShallowRef<Editor>;

function onUploadImageClick() {
	const input = document.createElement('input');
	input.type = 'file';

	input.onchange = async (event) => {
		const target = event.target as HTMLInputElement;
		if (target.files) {
			await uploadAndAddImage(target.files[0]);
		}
	};

	input.click();
}

async function uploadAndAddImage(file: File, position?: number) {
	const fileRes = await upload(file);

	editor.value
		.chain()
		.insertContentAt(position ?? editor.value.state.selection.anchor, {
			type: 'image',
			attrs: {
				src: fileRes.fileUrl,
			},
		})
		.focus()
		.run();
}

// Make input work correctly with FormKit
// Based on example: https://tiptap.dev/docs/editor/installation/nuxt#5-use-v-model
watch(
	() => props.context?.value,
	(value) => {
		const isSame = editor.value?.getHTML() === value;
		if (!isSame) {
			editor.value?.commands.setContent(value, false);
		}
	}
);

const defaultAiTextOptions: TextOptions = {
	language: 'nb',
};
</script>

<style scoped>
.menubar-button {
	@apply flex h-7 items-center rounded px-1 py-0.5 disabled:cursor-not-allowed disabled:opacity-50;
}
.is-active {
	@apply bg-slate-200;
}

.tippy-box {
	max-width: 100% !important;
}
</style>
<style>
.tiptap img {
	@apply h-auto max-w-full rounded-xl border border-slate-200 shadow-md;
}
.tiptap img.ProseMirror-selectednode {
	@apply outline outline-primary-500;
}
.ProseMirror-focused {
}
</style>
