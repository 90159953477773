import { useQuery } from '@tanstack/vue-query';
import { EntitySearchRow } from '~/codegen/axos-client';

type EntitySearchRowWithUrl = EntitySearchRow & {
	url: string;
};

interface Options {
	entityFilter: Ref<string | null>;
}

export default function (searchQuery: Ref<string>, options: Options) {
	const { api } = useApiClient();

	const { data, refetch, ...args } = useQuery({
		queryKey: ['globalSearch', { searchQuery }],
		queryFn: () => api.getSearchResult(searchQuery.value),
		enabled: computed(
			() => searchQuery.value !== null && searchQuery.value !== ''
		),
		staleTime: 0,
	});

	const { getEntityUrl } = useEntityUrl();

	const hits = computed(
		() =>
			data.value?.hits
				?.filter((it) => {
					if (options.entityFilter.value === null) return true;
					return it.entity === options.entityFilter.value;
				})
				.map((it) => ({
					...it,
					url: it
						? getEntityUrl(it.entityId, it.rowId, it?.metaDataJson)
						: '#',
				})) as EntitySearchRowWithUrl[]
	);

	return {
		hits: hits,
		results: data,
		...args,
	};
}
