import { InstanceSettings } from '~/codegen/axos-client';

const instanceSettings = ref<InstanceSettings>(null as any);

export default function () {
	if (instanceSettings.value === null) {
		const localInstanceSettings = localStorage.getItem('instanceSettings');
		if (localInstanceSettings) {
			instanceSettings.value = JSON.parse(localInstanceSettings);
		}
	}

	return {
		instanceSettings,
	};
}
