<template>
	<!-- Main sidebar with icons -->
	<nav
		class="z-20 shrink-0 bg-slate-700"
		:class="{
			'w-24 p-2': showSidebarLabels,
			'w-16 py-1.5': !showSidebarLabels,
		}"
	>
		<ul
			role="list"
			class="flex h-full flex-col gap-y-1"
			:class="{
				'items-left': showSidebarLabels,
				'items-center': !showSidebarLabels,
			}"
		>
			<!-- Close sidebar button-->
			<li>
				<button
					type="button"
					class="flex w-full items-center justify-center px-4 py-3 text-slate-200 lg:hidden"
					@click="toggleSidebar"
				>
					<span class="sr-only">Close sidebar</span>

					<Icon :name="ICON_X" class="h-7 w-7" aria-hidden="true" />
				</button>
			</li>

			<!-- Organisation-->
			<li
				v-if="
					hasModule(AxosModules.Organisation) &&
					(isAdmin || isEmployeeManager)
				"
			>
				<NuxtLink
					to="/organisations"
					:class="{
						'!border-slate-800/50 bg-slate-800 bg-opacity-60 text-white':
							isActive('/organisations'),
					}"
					class="font-regular group relative flex flex-col items-center rounded-md border border-transparent p-3 pb-2.5 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
					@click="closeSidebar"
				>
					<Icon
						:name="ICON_ORGANISATION"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showSidebarLabels">
						{{ $p('Organisation.Entity.Name') }}
					</span>

					<Tooltip v-else>
						{{ $p('Organisation.Entity.Name') }}
					</Tooltip>
				</NuxtLink>
			</li>

			<!-- HR-->
			<li v-if="hasModule([AxosModules.Hr, AxosModules.Employee])">
				<NuxtLink
					to="/employees"
					:class="{
						' !border-slate-800/50 bg-slate-800 bg-opacity-60 text-white':
							isActive('/employees') ||
							isActive('/allergies') ||
							isActive('/hr') ||
							isActive('/employee-meetings'),
					}"
					class="font-regular group relative flex flex-col items-center rounded-md border border-transparent p-3 pb-2.5 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
				>
					<Icon
						:name="ICON_HR"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showSidebarLabels">
						{{ $p('Core.Navigation.HR') }}
					</span>

					<Tooltip v-else>
						{{ $p('Core.Navigation.HR') }}
					</Tooltip>
				</NuxtLink>
			</li>

			<!-- CRM-->
			<li v-if="hasModule(AxosModules.Crm)">
				<NuxtLink
					to="/accounts"
					:class="{
						'!border-slate-800/50 bg-slate-800 bg-opacity-60 text-white':
							isActive('/account') ||
							isActive('/contacts') ||
							isActive('/crm'),
					}"
					class="font-regular group relative flex flex-col items-center rounded-md border border-transparent p-3 pb-2.5 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
				>
					<Icon
						:name="ICON_CRM"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showSidebarLabels">
						{{ $p('Core.Navigation.CRM') }}
					</span>

					<Tooltip v-else>
						{{ $p('Core.Navigation.CRM') }}
					</Tooltip>
				</NuxtLink>
			</li>

			<!-- Sales-->
			<li v-if="hasModule(AxosModules.Sales)">
				<NuxtLink
					to="/leads"
					:class="{
						'!border-slate-800/50 bg-slate-800 bg-opacity-60 text-white':
							isActive('/sales') || isActive('/leads'),
					}"
					class="font-regular group relative flex flex-col items-center rounded-md border border-transparent p-3 pb-2.5 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
				>
					<Icon
						:name="ICON_SALES"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showSidebarLabels">
						{{ $p('Core.Navigation.Sales') }}
					</span>

					<Tooltip v-else>
						{{ $p('Core.Navigation.Sales') }}
					</Tooltip>
				</NuxtLink>
			</li>
			<li v-if="hasModule(AxosModules.Finance)">
				<NuxtLink
					to="/products"
					:class="{
						'bg-slate-800 bg-opacity-60 text-white':
							isActive('/finance') || isActive('/products'),
					}"
					class="font-regular group relative flex flex-col items-center rounded-md p-3 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
				>
					<Icon
						:name="ICON_FINANCE"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showSidebarLabels">
						{{ $p('Core.Navigation.Finance') }}
					</span>

					<Tooltip v-else>
						{{ $p('Core.Navigation.Finance') }}
					</Tooltip>
				</NuxtLink>
			</li>

			<li class="flex-grow"></li>

			<!-- Admin -->
			<li v-if="isAdmin">
				<NuxtLink
					to="/admin"
					:class="{
						'!border-slate-800/50 bg-slate-800 bg-opacity-60 text-white':
							isActive('/admin'),
					}"
					class="font-regular group relative flex flex-col items-center rounded-md p-3 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
				>
					<Icon
						:name="ICON_ADMIN"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<span v-if="showSidebarLabels">
						{{ $p('Core.Navigation.Admin') }}
					</span>

					<Tooltip v-else>
						{{ $p('Core.Navigation.Admin') }}
					</Tooltip>
				</NuxtLink>
			</li>

			<!-- Ideas-->
			<Idea :show-label="showSidebarLabels" />

			<!-- Toggle sidebar labels-->
			<li class="mt-2 border-t border-slate-600 pt-4">
				<button
					@click="toggleSidebarLabels"
					class="font-regular group relative flex w-full flex-col items-center rounded-md border border-transparent p-3 pb-4 pt-1.5 leading-6 text-slate-400 transition-all hover:text-white"
					:class="{
						'mt-0.5': showSidebarLabels,
						'mt-1': !showSidebarLabels,
					}"
				>
					<Icon
						:name="
							showSidebarLabels
								? ICON_CHEVRON_LEFT_DOUBLE
								: ICON_CHEVRON_RIGHT_DOUBLE
						"
						class="h-6 w-6 shrink-0"
						aria-hidden="true"
					/>

					<Tooltip>
						<template v-if="showSidebarLabels">
							{{ $s('Core.Navigation.CollapseSidebar') }}
						</template>
						<template v-else>
							{{ $s('Core.Navigation.ExpandSidebar') }}
						</template>
					</Tooltip>
				</button>
			</li>
		</ul>
	</nav>
</template>

<script setup lang="ts">
const showSidebarLabels = useLocalStorage('showSidebarLabels', true);

const { closeSidebar, toggleSidebar } = useSidebar();

const { hasModule, isEmployeeManager, isAdmin } = useAccessControl();

const route = useRoute();
const isActive = (path: string) => {
	return route.path.startsWith(path);
};
const toggleSidebarLabels = () => {
	showSidebarLabels.value = !showSidebarLabels.value;
};
</script>
