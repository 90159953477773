function generateCorrelationId() {
	return `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
}

export default function () {
	const config = useRuntimeConfig();
	const { impersonatingId } = useImpersonate();

	async function fetchWithDefaults(url: RequestInfo, init?: RequestInit) {
		const startTime = performance.now();
		const correlationId = generateCorrelationId();

		const finalUrl = url.toString().startsWith('http')
			? url
			: config.public.BFF_URL + '/api' + url;

		const headers = init?.headers || {};

		// @ts-ignore
		headers['Content-Type'] = 'application/json';
		// @ts-ignore
		headers['Accept'] = 'application/json';
		// @ts-ignore
		headers['x-correlation-id'] = correlationId;

		const result = await fetch(finalUrl, {
			method: 'GET',
			...init,
			credentials: 'include',
			headers: impersonatingId.value
				? {
						...headers,
						'Impersonate-Id': impersonatingId.value,
				  }
				: headers,
		});

		const endTime = performance.now();
		const duration = endTime - startTime;

		console.debug(
			`Request to ${finalUrl} completed in ${duration}ms (Correlation: ${correlationId})`
		);

		if (!result.ok) {
			console.error('Error Response:', {
				url: finalUrl,
				status: result.status,
				correlationId,
				duration,
			});

			if (result.status === 401) {
				throw createError({
					message: 'Unauthorized',
					data: { ...result, correlationId },
					statusCode: 401,
					status: 401,
				});
			}

			const error = await result.json();
			throw createError({
				message:
					error?.resourceId ?? error?.message ?? 'Error from API',
				data: { ...error, correlationId },
			});
		}

		return result;
	}

	return {
		fetchWithDefaults,
	};
}
